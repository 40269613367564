import React from 'react'
import Link from 'gatsby-link'
import Logo from '../images/kennethau.png'

const Header = ({ siteTitle }) => (
  <div>
    <nav
      className="header navbar is-white"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <a className="logoParent" href="https://kennethau.com">
          <img
            className="logo"
            alt="site logo"
            src={Logo}
            width="250px"
            height="120px"
          />
        </a>

        {/* mobile menu */}
        <a
          role="button"
          className="navbar-burger burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarHeader"
          onClick={() => {
            var burger = document.querySelector('.burger')
            var nav = document.querySelector('#navbarHeader')
            burger.classList.toggle('is-active')
            nav.classList.toggle('is-active')
          }}
        >
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </a>
      </div>

      <div id="navbarHeader" className="navbar-menu">
        <div className="navbar-end">
          <Link className="navbar-item" to="/">
            Home
          </Link>

          {/* <Link className="navbar-item" to="/portfolio">
            Portfolio
          </Link> */}

          {/* <Link className="navbar-item" to="/side-proj">
            Side Projects
          </Link> */}

          <Link className="navbar-item" to="/resources">
            Resources
          </Link>
          <Link className="navbar-item" to="/blog">
            Blog
          </Link>
          <Link className="navbar-item" to="/about">
            About
          </Link>
        </div>
      </div>
    </nav>
  </div>
)

export default Header
